/* block top search */
@import "../../autoload/compatibility";

@mixin aligend-cell {
    display: table-cell;
    vertical-align: middle;
}

#search_block_top {
    position: static;
    display: table;
    height: 65px;
    padding-right: 0;
    z-index: 30;
    @media (min-width: $screen-sm-min) {
        right: 8.33333%;
    }
    @media (max-width: $screen-xs-max) {
        height: 55px;
        float: right;
        position: absolute;
        right: 20px;
        @include transition(all 350ms ease-out);

        &.focus {
            width: calc(100% - 100px);
        }
        &:not(.focus) {
            #search_query_top {
                width: 0;
                padding: 0;
            }
        }

        &.background {
            z-index: 10;
        }
    }

    p {
        padding: 0;
    }

    #search_query_top {
        padding: 0 15px;
        height: 30px;
        display: table-cell;
        margin-top: 2px;
        border: 1px solid #666;
        border-right: 0 !important;
        @include border-left-radius(15px);
        color: #666;
        background: #fff;
        float: left;

        @media (max-width: $screen-xs-max) {
            float: right;
            @include transition(all 350ms ease-out);
        }
    }

    .button {
        border: none;
        border-radius: 0;
        color: #fff;
        text-transform: uppercase;
        background: url(img/bg_search_submit.png) repeat-x 0 0 #101010;
        float: left;
        height: 25px;
    }
    label {
        @include aligend-cell;
        height: 30px;
        min-width: 200px;
        padding: 0 12px;
        letter-spacing: 2.5px;
        font-size: 18px;
        font-weight: 300;
        color: #fff;
    }

}

form#searchbox {
    font-family: $font-default;
    width: 100%;
    @include aligend-cell;

    @media (max-width: $screen-xs-max) {
        margin-top: 10px;
        display: inline-block;
    }

    input#search_query_block {
        border: 1px solid #CCCCCC;
        -webkit-border-radius: 3px !important;
        -moz-border-radius: 3px !important;
        border-radius: 3px !important;
        height: 18px;
        margin-top: 10px;
    }
    .input-group {
        width: 100%;
    }
    .input-group-btn {

        height: 32px;

        button {
            border-radius: 0;
        }
    }
}


